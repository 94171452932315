import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getToken, requestHandler } from '../../helpers/Admin/Utils';
import {
    AuthResponse,
    LoginResponse,
    CourseSelectOptions,
    UserSelectOptions,
    UserGroup,
    UserGroupsData,
    Configurations,
} from '../../../../server/types/admin/core';
import {
    Course,
    User,
    SignupCourse,
    Signup,
} from '../../../../server/types/core';
import { GridRowId } from '@mui/x-data-grid';

function useGetCourses(): UseQueryResult<Course[], AxiosError> {
    return useQuery({
        queryKey: ['admin-courses'],
        queryFn: () => getCourses(),
        gcTime: 0,
    });
}

function useGetDeletedCourses(): UseQueryResult<Course[], AxiosError> {
    return useQuery({
        queryKey: ['deleted-courses'],
        queryFn: () => getDeletedCourses(),
        gcTime: 0,
    });
}

function useGetCourseSelectOptions(id: number | null): UseQueryResult<CourseSelectOptions, AxiosError> {
    return useQuery({
        queryKey: ['course-select-options', id],
        queryFn: () => getCourseSelectOptions(id),
        gcTime: 0,
    });
}

function useGetUsers(): UseQueryResult<User[], AxiosError> {
    return useQuery({
        queryKey: ['users'],
        queryFn: () => getUsers(),
        gcTime: 0,
    });
}

function useGetDeletedUsers(): UseQueryResult<User[], AxiosError> {
    return useQuery({
        queryKey: ['deleted-users'],
        queryFn: () => getDeletedUsers(),
        gcTime: 0,
    });
}

function useGetUserSelectOptions(): UseQueryResult<UserSelectOptions, AxiosError> {
    return useQuery({
        queryKey: ['user-select-options'],
        queryFn: () => getUserSelectOptions(),
        gcTime: 0,
    });
}

function useGetPhysicalCourses(): UseQueryResult<SignupCourse[], AxiosError> {
    return useQuery({
        queryKey: ['physical-courses'],
        queryFn: () => getPhysicalCourses(),
        gcTime: 0,
    });
}

function useGetCourseSignups(id: number): UseQueryResult<Signup[], AxiosError> {
    return useQuery({
        queryKey: ['course-signups', id],
        queryFn: () => getCourseSignups(id),
        gcTime: 0,
    });
}

function useGetCompletedCourses(id: number): UseQueryResult<Course[], AxiosError> {
    return useQuery({
        queryKey: ['completed-courses', id],
        queryFn: () => getCompletedCourses(id),
        gcTime: 0,
    });
}

function useGetUserGroups(): UseQueryResult<UserGroup[], AxiosError> {
    return useQuery({
        queryKey: ['user-groups'],
        queryFn: () => getUserGroups(),
        gcTime: 0,
    });
}

function useGetUserGroupsData(): UseQueryResult<UserGroupsData, AxiosError> {
    return useQuery({
        queryKey: ['user-groups-data'],
        queryFn: () => getUserGroupsData(),
        gcTime: 0,
    });
}

function useGetConfigurations(): UseQueryResult<Configurations, AxiosError> {
    return useQuery({
        queryKey: ['configuration'],
        queryFn: () => getConfigurations(),
        gcTime: 0,
    });
}

function getLoginState(): Promise<AuthResponse> {
    const token: string | null = getToken();

    if (!token) {
        return new Promise((resolve) => resolve({ loggedIn: false }));
    }

    return axios.post<AuthResponse>('/admin-api/getUser', { token })
        .then((response) => response.data);
}

function loginUser(email: string, password: string): Promise<LoginResponse> {
    return axios.post<LoginResponse>('/admin-api/login', { email, password })
        .then((response) => response.data);
}

function logoutUser(id: number): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/logout',
        data: { id },
    });
}

function getCourses(): Promise<Course[]> {
    return requestHandler<Course[]>({
        type: 'post',
        endpoint: '/admin-api/getCourses',
        data: {},
    });
}

function deleteCourse(id: GridRowId, permanently?: boolean): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/deleteCourse',
        data: { id, permanently },
    });
}

function deleteCourses(ids: GridRowId[]): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/deleteCourses',
        data: { ids },
    });
}

function getDeletedCourses(): Promise<Course[]> {
    return requestHandler<Course[]>({
        type: 'post',
        endpoint: '/admin-api/getDeletedCourses',
        data: {},
    });
}

function restoreCourse(id: GridRowId): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/restoreCourse',
        data: { id },
    });
}

function restoreCourses(ids: GridRowId[]): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/restoreCourses',
        data: { ids },
    });
}

function createOrUpdateCourse(course: Course): Promise<string> {
    return requestHandler<string>({
        type: 'formData',
        endpoint: '/admin-api/createOrUpdateCourse',
        data: course,
    });
}

function getCourse(id: number): Promise<Course> {
    return requestHandler<Course>({
        type: 'post',
        endpoint: '/admin-api/getCourse',
        data: { id },
    });
}

function getCourseSelectOptions(id: number | null): Promise<CourseSelectOptions> {
    return requestHandler<CourseSelectOptions>({
        type: 'post',
        endpoint: '/admin-api/getCourseSelectOptions',
        data: { id },
    });
}

function getUsers(): Promise<User[]> {
    return requestHandler<User[]>({
        type: 'post',
        endpoint: '/admin-api/getUsers',
        data: {},
    });
}

function deleteUser(id: GridRowId, permanently?: boolean): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/deleteUser',
        data: { id, permanently },
    });
}

function deleteUsers(ids: GridRowId[]): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/deleteUsers',
        data: { ids },
    });
}

function getDeletedUsers(): Promise<User[]> {
    return requestHandler<User[]>({
        type: 'post',
        endpoint: '/admin-api/getDeletedUsers',
        data: {},
    });
}

function restoreUser(id: GridRowId): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/restoreUser',
        data: { id },
    });
}

function restoreUsers(ids: GridRowId[]): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/restoreUsers',
        data: { ids },
    });
}

function getUserSelectOptions(): Promise<UserSelectOptions> {
    return requestHandler<UserSelectOptions>({
        type: 'post',
        endpoint: '/admin-api/getUserSelectOptions',
        data: {},
    });
}

function createOrUpdateUser(user: User): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/createOrUpdateUser',
        data: user,
    });
}

function getFrontendUser(id: number): Promise<User> {
    return requestHandler<User>({
        type: 'post',
        endpoint: '/admin-api/getFrontendUser',
        data: { id },
    });
}

function getPhysicalCourses(): Promise<SignupCourse[]> {
    return requestHandler<SignupCourse[]>({
        type: 'post',
        endpoint: '/admin-api/getPhysicalCourses',
        data: {},
    });
}

function getCourseSignups(id: number): Promise<Signup[]> {
    return requestHandler<Signup[]>({
        type: 'post',
        endpoint: '/admin-api/getCourseSignups',
        data: { id },
    });
}

function updateSignups(
    {
        courseId,
        selectedAttendees,
        unselectedAttendees,
    }: {
        courseId: number,
        selectedAttendees: number[],
        unselectedAttendees: number[],
    }
): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/updateSignups',
        data: { courseId, selectedAttendees, unselectedAttendees },
    });
}

function getCompletedCourses(id: number): Promise<Course[]> {
    return requestHandler<Course[]>({
        type: 'post',
        endpoint: '/admin-api/getCompletedCourses',
        data: { id },
    });
}

function getUserGroups(): Promise<UserGroup[]> {
    return requestHandler<UserGroup[]>({
        type: 'post',
        endpoint: '/admin-api/getUserGroups',
        data: {},
    });
}

function deleteUserGroup(groupKey: GridRowId): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/deleteUserGroup',
        data: { groupKey },
    });
}

function deleteUserGroups(groupKeys: GridRowId[]): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/deleteUserGroups',
        data: { groupKeys },
    });
}

function getUserGroupsData(): Promise<UserGroupsData> {
    return requestHandler<UserGroupsData>({
        type: 'post',
        endpoint: '/admin-api/getUserGroupsData',
        data: {},
    });
}

function getUserGroup(groupKey: string): Promise<UserGroup> {
    return requestHandler<UserGroup>({
        type: 'post',
        endpoint: '/admin-api/getUserGroup',
        data: { groupKey },
    });
}

function createOrUpdateUserGroup(userGroup: UserGroup): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/createOrUpdateUserGroup',
        data: userGroup,
    });
}

function getConfigurations(): Promise<Configurations> {
    return requestHandler<Configurations>({
        type: 'post',
        endpoint: '/admin-api/getConfigurations',
        data: {},
    });
}

function saveConfigurations(configurations: Configurations): Promise<string> {
    return requestHandler<string>({
        type: 'formData',
        endpoint: '/admin-api/saveConfigurations',
        data: configurations,
    });
}

function importDiplomaFiles(): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/admin-api/importDiplomaFiles',
        data: {},
    });
}

export {
    useGetCourses,
    useGetDeletedCourses,
    useGetCourseSelectOptions,
    useGetUsers,
    useGetDeletedUsers,
    useGetUserSelectOptions,
    useGetPhysicalCourses,
    useGetCourseSignups,
    useGetCompletedCourses,
    useGetUserGroups,
    useGetUserGroupsData,
    useGetConfigurations,
    getLoginState,
    loginUser,
    logoutUser,
    deleteCourse,
    deleteCourses,
    restoreCourse,
    restoreCourses,
    createOrUpdateCourse,
    getCourse,
    deleteUser,
    deleteUsers,
    restoreUser,
    restoreUsers,
    createOrUpdateUser,
    getFrontendUser,
    updateSignups,
    deleteUserGroup,
    deleteUserGroups,
    getUserGroup,
    createOrUpdateUserGroup,
    saveConfigurations,
    importDiplomaFiles,
}
