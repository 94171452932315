import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import AuthContextProvider from './components/AuthContextProvider';
import AdminAuthContextProvider from './components/Admin/AuthContextProvider';
import router from './components/Router';

const queryClient: QueryClient = new QueryClient();

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

function App(): React.ReactElement {
    return (
        <QueryClientProvider client={queryClient}>
            <AdminAuthContextProvider>
                <AuthContextProvider>
                    <RouterProvider router={router} />
                </AuthContextProvider>
            </AdminAuthContextProvider>
        </QueryClientProvider>
    );
}

export default App;
