import React, { memo, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from '@tanstack/react-router';
import { AuthContext } from '../contexts/AuthContext';
import { useGetCourse, setCourseUserData, getCourseDiploma } from '../api/Utils';
import CourseSkeleton from './CourseSkeleton';
import ScormPlayer from './ScormPlayer';
import { toast } from 'react-toastify';
import {
    FormControl,
    Button,
    FormHelperText,
    FormControlLabel,
    Checkbox,
    Autocomplete,
    AutocompleteRenderInputParams,
    TextField, Skeleton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import type { Course as CourseEntity } from '../../../server/types/core';
import '../styles/Course.css';
import placeholder from "../media/placeholder.svg";

declare global {
    interface Window { API: any; }
}

const Course = memo(function Course(): React.ReactElement {
    const { courseId }: { courseId: string } = useParams({ strict: false });
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate({ from: `/courses/${courseId}` });
    const [userConsented, setUserConsented] = useState<boolean>(false);
    const [playerVisible, setPlayerVisible] = useState<boolean>(false);
    const [childCourse, setChildCourse] = useState<CourseEntity | undefined>(undefined);
    const { data: courseData, isLoading, error, refetch } = useGetCourse({
        courseId: parseInt(courseId),
        userId: user?.id,
    });
    const course: CourseEntity | undefined = useMemo(() => {
        return childCourse ?? courseData;
    }, [childCourse, courseData]);
    const targetAudienceString: string = useMemo((): string => {
        if (!course) {
            return '';
        }

        return (course.targetAudience as string[])?.reduce(
            (map: string, audience: string, index: number, array: string[]) => {
                map += array.length - 1 !== index
                    ? t(audience) + ' & '
                    : t(audience);
                return map;
            },
            '',
        );
    }, [course, t]);
    const reachedSignupLimit: boolean = useMemo((): boolean => {
        if (!course) {
            return false;
        }

        if (course?.userData && course.userData.status === 'signed up') {
            return false;
        }

        return course.format === 'physical' && course.amountOfSignups! >= course.signupLimit;
    }, [course]);
    const childCourses: CourseEntity[] = useMemo((): CourseEntity[] => {
        return !courseData
            ? []
            : courseData.childCourses!
                .filter((childCourse: CourseEntity) => !!childCourse.startTime);
    }, [courseData]);

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
    }

    useEffect(() => {
        if (childCourses.length > 0) {
            setChildCourse(!childCourse ? childCourses[0] : childCourses.find((child: CourseEntity): boolean => child.id === childCourse.id));
        }
    }, [childCourses, childCourse]);

    const scormAPI = useCallback(() => {
        if (!user || !course?.userData) {
            return {};
        }

        const finalStatus: string[] = ['passed', 'complete', 'completed'];
        const data: { [key: string]: string } = {
            'cmi.core.student_id': user.id!.toString(),
            'cmi.core.student_name': user.name,
            'cmi.core.lesson_location': finalStatus.includes(course.userData.status)
                ? ''
                : course.userData.location,
            'cmi.core.lesson_status': finalStatus.includes(course.userData.status)
                ? 'not attempted'
                : course.userData.status,
            'cmi.suspend_data': finalStatus.includes(course.userData.status)
                ? ''
                : course.userData.data,
            'cmi.core.score.raw': finalStatus.includes(course.userData.status)
                ? '0'
                : course.userData.score.toString(),
        };

        return {
            LMSInitialize: () => {
                if (course.userData!.status === 'not attempted' || finalStatus.includes(course.userData!.status as string)) {
                    setCourseUserData({
                        courseId: course.id,
                        userId: user.id,
                        status: 'not attempted',
                        data: '',
                        location: '',
                        score: 0,
                    })
                        .catch((error: AxiosError): void => {
                            const { responseText } = error.request;
                            const errorMessage: string = responseText ? responseText : error.message;
                            toast.error(t(errorMessage), {
                                hideProgressBar: true,
                            });
                        });
                }

                return 'true';
            },
            LMSCommit: () => {
                return 'true';
            },
            LMSFinish: () => {
                const status: string = data['cmi.core.lesson_status'];

                if (user && course?.userData) {
                    setCourseUserData({
                        courseId: course.id,
                        userId: user.id,
                        status,
                        data: data['cmi.suspend_data'],
                        location: data['cmi.core.lesson_location'],
                        score: data['cmi.core.score.raw']
                            ? parseInt(data['cmi.core.score.raw'])
                            : 0,
                    })
                        .then((): void => {
                            refetch();
                            setPlayerVisible(false);
                        })
                        .catch((error: AxiosError): void => {
                            const { responseText } = error.request;
                            const errorMessage: string = responseText ? responseText : error.message;
                            toast.error(t(errorMessage), {
                                hideProgressBar: true,
                            });
                        });
                }

                return 'true';
            },
            LMSGetValue: (model: string) => {
                return data[model] || '';
            },
            LMSSetValue: (model: string, value: string) => {
                data[model] = value;

                return 'true';
            },
            LMSGetLastError: () => {
                return '0';
            },
            LMSGetErrorString: (errorCode: string) => {
                return 'No error';
            },
            LMSGetDiagnostic: (errorCode: string) => {
                return 'No error';
            }
        };
    }, [
        user,
        course,
        t,
        refetch,
    ]);

    window.API = (scormAPI)();

    const getButtonText = useCallback((): string => {
        if (!course) {
            return '';
        }

        if (!course?.userData) {
            return 'logIn';
        }

        if (course.format === 'physical') {
            if (course.userData.status === 'signed up') {
                return 'unsubscribe';
            }

            return 'signUp';
        }

        switch (course.userData.status) {
            case 'incomplete':
            case 'failed':
                return 'continue';
            case 'passed':
            case 'complete':
            case 'completed':
                return 'redo';
            default:
                return 'start';
        }
    }, [course]);

    const startHandler = useCallback((): void => {
        if (!course) {
            return;
        }

        if (!user || !course?.userData) {
            navigate({ to: '/login', search: { redirect: `/courses/${courseId}` } });
            return;
        }

        if (course.format === 'physical') {
            const status = course.userData.status === 'signed up' ? 'not attempted' : 'signed up';
            const message = course.userData.status === 'signed up' ? 'cancelledSignUpForCourse' : 'signedUpForCourse';
            setCourseUserData({
                courseId: course.id,
                userId: user.id,
                status,
                data: '',
                location: '',
                score: 0,
            })
                .then((): void => {
                    toast.success(t(message), {
                        hideProgressBar: true,
                    });
                    refetch();
                })
                .catch((error: AxiosError): void => {
                    const { responseText } = error.request;
                    const errorMessage: string = responseText ? responseText : error.message;
                    toast.error(t(errorMessage), {
                        hideProgressBar: true,
                    });
                });
            return;
        }

        setPlayerVisible(true);
    }, [
        course,
        user,
        navigate,
        courseId,
        t,
        refetch,
    ]);

    const disabledHandler = useCallback((): boolean => {
        if (!course) {
            return true;
        }

        if (course.format === 'physical' && new Date() > new Date(course.startTime!)) {
            return true;
        }

        if (course.format === 'physical' && course.userData?.status === 'complete') {
            return true;
        }

        if (!course?.userData) {
            return false;
        }

        if (course.hasPrice && !userConsented) {
            return true;
        }

        return reachedSignupLimit;
    }, [
        course,
        userConsented,
        reachedSignupLimit,
    ]);

    const childCourseOptionEqualToValueHandler = useCallback((option: CourseEntity, value: CourseEntity): boolean => {
        return option.id === value.id;
    }, []);

    const childCourseOptionLabelHandler = useCallback((option: CourseEntity) => option.startTime!, []);

    const childCourseRenderInputHandler = useCallback(({ inputProps, ...rest }: AutocompleteRenderInputParams) => {
        return (<TextField
            {...rest}
            label={t('selectTime')}
            inputProps={{ ...inputProps, readOnly: true }}
        />);
    }, [t]);

    const diplomaHandler = useCallback((): void => {
        if (!course || !user) {
            return;
        }

        getCourseDiploma(course.id!, user.id!)
            .then((data): void => {
                // prepare file data
                const blob = new Blob([data], {
                    type: 'application/pdf',
                });
                const url = URL.createObjectURL(blob);
                // download action
                const a = document.createElement('a');
                a.href = url;
                a.download = 'diploma.pdf';
                document.body.appendChild(a);
                a.click();
                // clean-up
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            });
    }, [course, user, t]);

    return (
        <div className="course-container">
            { isLoading && <CourseSkeleton /> }
            { !isLoading && course && !playerVisible &&
                <div className="course-wrapper">
                    {!childCourse && childCourses.length > 0
                        ? <Skeleton
                            variant="rectangular"
                            animation="wave"
                            className="course-image-skeleton"
                        >
                            <img src={placeholder} alt="placeholder" className="course-image"/>
                        </Skeleton>
                        : <img src={(course.thumbnail as string)} alt={course.title}
                               className="course-image"/>
                    }
                    <div className="course-information">
                        <div className="course-title">{course.title}</div>
                        <div className="course-description">{
                            course.richTextDescription
                                ? course.richTextDescription
                                : course.description
                        }</div>
                    </div>
                    <div className="course-details">
                        <div className="target-audience-container">
                            <div className="course-information-label">{t('targetAudience')}</div>
                            <div className="course-information-value">
                                <div className="target-audience">{targetAudienceString}</div>
                            </div>
                        </div>
                        <div className="course-category-container">
                            <div className="course-information-label">{t('courseCategory')}</div>
                            <div className="course-information-value">{t(course.courseCategory!)}</div>
                        </div>
                        <div className="format-container">
                            <div className="course-information-label">{t('format')}</div>
                            <div className="course-information-value">{t(course.format)}</div>
                        </div>
                        <div className="duration-container">
                            <div className="course-information-label">{t('duration')}</div>
                            <div className="course-information-value">
                                {course.duration + ' ' + (course.duration > 1 ? t(course.durationType) : t(course.durationType.slice(0, -1)))}
                            </div>
                        </div>
                        {course.format === 'physical' &&
                            <>
                                <div className="available-seats-container">
                                    <div className="course-information-label">{t('availableSeats')}</div>
                                    <div className="course-information-value">
                                        {`${course.signupLimit - course.amountOfSignups!} ${t('ofLowercase')} ${course.signupLimit} ${t('seatsAvailable')}`}
                                    </div>
                                </div>
                                <div className="location-container">
                                    <div className="course-information-label">{t('location')}</div>
                                    <div className="course-information-value">{course.location}</div>
                                </div>
                                <div className="start-time-container">
                                    <div className="course-information-label">{t('startTime')}</div>
                                    <div className="course-information-value">{course.startTime}</div>
                                </div>
                            </>
                        }
                        {!course.isPublic &&
                            <div className="course-type-container">
                                <div className="course-information-label">{t('courseType')}</div>
                                <div className="course-information-value">{t(course.courseType)}</div>
                            </div>
                        }
                        {course.hasPrice &&
                            <div className="price-container">
                                <div className="course-information-label">{t('price')}</div>
                                <div className="course-information-value">
                                    {`${course.price.toString()} NOK`}
                                </div>
                            </div>
                        }
                    </div>
                    {childCourses.length > 0 &&
                        <Autocomplete
                            disablePortal
                            id="child-course-select"
                            options={childCourses}
                            isOptionEqualToValue={childCourseOptionEqualToValueHandler}
                            getOptionLabel={childCourseOptionLabelHandler}
                            value={childCourse}
                            loading={isLoading}
                            defaultValue={childCourses[0]}
                            disableClearable={true}
                            onChange={(event: any, newValue: CourseEntity): void => setChildCourse(newValue)}
                            renderInput={childCourseRenderInputHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    }
                    <FormControl>
                        {course.userData && course.hasPrice &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userConsented}
                                        onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                                setUserConsented((prevState: boolean) => !prevState);
                                            }
                                        }
                                        sx={{
                                            color: 'var(--background-color)',
                                            '&.Mui-checked': {
                                                color: 'var(--background-color)',
                                            },
                                        }}
                                    />
                                }
                                label={t('userPriceConsent')}
                            />
                        }
                        <Button
                            className="course-button"
                            disabled={disabledHandler()}
                            onClick={startHandler}
                        >{t(getButtonText())}</Button>
                        {course.userData && reachedSignupLimit &&
                            <div className="helper-text-wrapper">
                                <FormHelperText className="course-helper-text">
                                    {t('courseReachedSignupLimit')}
                                </FormHelperText>
                            </div>
                        }
                    </FormControl>
                    {course.hasDiploma && course.userData && course.userData.completionAmount! > 0 &&
                        <Button
                            className="diploma-button"
                            onClick={diplomaHandler}
                        >{t('downloadDiploma')}</Button>
                    }
                </div>
            }
            {!isLoading && course && playerVisible &&
                <div className="player-wrapper">
                    <ScormPlayer
                        scormTitle={course.title}
                        scormUrl={course.scormFile as string}
                    />
                </div>
            }
        </div>
    );
});

export default Course;
