import axios from 'axios';

function getToken(): string | null {
    return localStorage.getItem('auth-token');
}

function requestHandler<ReturnType>(
    {
        type,
        endpoint,
        data,
        config,
    }: {
        type: 'post',
        endpoint: string,
        data?: { [key: string]: any },
        config?: { [key: string]: any },
    }
): Promise<ReturnType> {
    const token: string | null = getToken();

    if (!token) {
        return new Promise((resolve, reject) => reject('noTokenProvided'));
    }

    switch (type) {
        case 'post':
            return axios.post<ReturnType>(endpoint, { ...data, token }, config ?? {})
                .then((response) => response.data);
        default:
            return new Promise((resolve, reject) => reject('invalidRequestType'));
    }
}

export { getToken, requestHandler }
