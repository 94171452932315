import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import type { PageElement } from '../../../server/types/sanity';

function useGetPageElements(page: string, language: string): UseQueryResult<PageElement[], AxiosError> {
    return useQuery({
        queryKey: ['elements', page, language],
        queryFn: () => getPageElements(page, language),
        staleTime: 1000 * 60 * 5,
    });
}

function getPageElements(page: string, language: string): Promise<PageElement[]> {
    return axios.post<PageElement[]>('/sanity/getPageElements', { page, language })
        .then((response) => response.data);
}

export { useGetPageElements }
