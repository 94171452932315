import React, { memo, useContext, useState, useCallback } from 'react';
import { Link, useNavigate } from '@tanstack/react-router';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../api/Utils';
import { AxiosError } from 'axios';
import { Drawer } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { toast } from 'react-toastify';
import LanguageSwitcher from './LanguageSwitcher';
import logo from '../media/logo.svg';
import '../styles/Header.css';
import 'bootstrap/dist/css/bootstrap.css';

const Header = memo(function Header(): React.ReactElement {
    const { loggedIn, user, updateLoginState } = useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate({ from: window.location.pathname });
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const logoutHandler = useCallback((mobileMenu?: boolean): void => {
        if (mobileMenu) {
            setDrawerVisible(false);
        }
        logoutUser(user!.id!)
            .then(() => {
                localStorage.removeItem('auth-token');
                updateLoginState();
                navigate({ to: '/' });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            });
    }, [user, updateLoginState, navigate, t]);

    return (
        <div className="header-container">
            <div className="desktop-header-container">
                <div className="left-links-container">
                    <Link to="/" className="header-link logo-link">
                        <img src={logo} className="header-logo" alt="logo"/>
                    </Link>
                </div>
                <div className="right-links-container">
                    {
                        loggedIn
                            ?
                            <>
                                <Link
                                    to="/profile"
                                    className="header-link profile-link"
                                >
                                    {t('profile')}
                                </Link>
                                <button
                                    onClick={() => logoutHandler()}
                                    className="header-link logout-link"
                                >
                                    {t('logout')}
                                </button>
                            </>
                            : <Link
                                to="/login"
                                className="header-link login-link"
                            >
                                {t('login')}
                            </Link>
                    }
                    <LanguageSwitcher/>
            </div>
            </div>
            <div className="mobile-header-container">
                <div className="left-links-container">
                    <Link to="/" className="header-link logo-link">
                        <img src={logo} className="header-logo" alt="logo"/>
                    </Link>
                </div>
                <div className="right-links-container">
                    <button
                        onClick={() => setDrawerVisible(true)}
                        className="header-link drawer-link"
                    >
                        <Menu sx={{ width: '36px', height: '36px', boxSizing: 'content-box' }} />
                    </button>
                    <Drawer
                        open={drawerVisible}
                        onClose={() => setDrawerVisible(false)}
                    >
                        <div className="drawer-container">
                            <LanguageSwitcher/>
                            {
                                loggedIn
                                    ? <button
                                        onClick={() => logoutHandler()}
                                        className="header-link logout-link"
                                    >
                                        {t('logout')}
                                    </button>
                                    : <Link
                                        to="/login"
                                        className="header-link login-link"
                                        onClick={() => setDrawerVisible(false)}
                                    >{t('login')}</Link>
                            }
                        </div>
                    </Drawer>
                </div>
            </div>
        </div>
    );
});

export default Header;
