import React, { memo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from '@tanstack/react-router';
import {
    Backdrop,
    CircularProgress,
    Theme,
    TextField,
    Button,
    FormControlLabel,
    Switch,
    Autocomplete,
    AutocompleteRenderInputParams, Checkbox,
} from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import {
    useGetUserSelectOptions,
    getFrontendUser,
    createOrUpdateUser,
} from '../../api/Admin/Utils';
import { toast } from 'react-toastify';
import { validEmail, validPassword } from '../../helpers/Regex';
import { UserGroup } from '../../../../server/types/admin/core';
import { User } from '../../../../server/types/core';
import { AxiosError } from 'axios';
import '../../styles/Admin/User.css';

const AdminUser = memo(function AdminUser(): React.ReactElement {
    const { userId }: { userId: string } = useParams({ strict: false });
    const urlPath: string = window.location.pathname;
    const { t } = useTranslation();
    const navigate = useNavigate({ from: urlPath.includes('edit') ? urlPath.replace(userId, '$userId') : urlPath });
    const [user, setUser] = useState<User | null>(null);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [companyAddress, setCompanyAddress] = useState<string>('');
    const [changePassword, setChangePassword] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [isActive, setIsActive] = useState<boolean>(false);
    const [userGroup, setUserGroup] = useState<UserGroup>({ groupKey: 'none', groupName: 'None' });
    const [invalidFields, setInvalidFields] = useState<{ [key: string]: boolean }>({
        name: false,
        email: false,
        companyName: false,
        password: false,
        repeatPassword: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const { data: userSelectOptions, isLoading, error } = useGetUserSelectOptions();

    if (error) {
        const { responseText } = error.request;
        const errorMessage: string = responseText ? responseText : error.message;
        toast.error(t(errorMessage), {
            hideProgressBar: true,
        });
        navigate({ to: '/manage-users' });
    }

    const updateUserData = useCallback(async (): Promise<void> => {
        try {
            setLoading(true);
            const userData: User = await getFrontendUser(parseInt(userId));
            setUser(userData);
            setName(userData.name);
            setEmail(userData.email);
            setPhoneNumber(userData.phoneNumber);
            setCompanyName(userData.companyName);
            setCompanyAddress(userData.companyAddress);
            setIsActive(userData.isActive!);
            setUserGroup(userData.userGroup as UserGroup);
            setLoading(false);
        } catch (e: any) {
            const { responseText } = e.request;
            const errorMessage: string = responseText ? responseText : e.message;
            toast.error(t(errorMessage), {
                hideProgressBar: true,
            });
            navigate({ to: '/manage-users' });
        }
    }, [userId, t, navigate]);

    useEffect(() => {
        if (userId) {
            updateUserData();
        }
    }, [userId, updateUserData]);

    const validateFields = useCallback((): boolean => {
        const nameInvalid: boolean = name === '';
        const emailInvalid: boolean = email === ''
            || !validEmail.test(email)
            || (!userId && userSelectOptions!.emails.includes(email))
            || (!!userId && email !== user!.email && userSelectOptions!.emails.includes(email));
        const companyNameInvalid: boolean = companyName === '';
        const passwordInvalid: boolean = (!userId || changePassword) && (password === '' || !validPassword.test(password));
        const repeatPasswordInvalid: boolean = (!userId || changePassword) && (repeatPassword !== password);

        setInvalidFields({
            name: nameInvalid,
            email: emailInvalid,
            companyName: companyNameInvalid,
            password: passwordInvalid,
            repeatPassword: repeatPasswordInvalid,
        });

        return nameInvalid
            || emailInvalid
            || companyNameInvalid
            || passwordInvalid
            || repeatPasswordInvalid;
    }, [
        name,
        email,
        userId,
        user,
        userSelectOptions,
        companyName,
        changePassword,
        password,
        repeatPassword,
    ]);

    const hasChanged = useCallback((): boolean => {
        return name !== user!.name
            || email !== user!.email
            || phoneNumber !== user!.phoneNumber
            || companyName !== user!.companyName
            || companyAddress !== user!.companyAddress
            || changePassword
            || isActive !== user!.isActive
            || userGroup.groupKey !== (user!.userGroup as UserGroup).groupKey;
    }, [
        name,
        user,
        email,
        phoneNumber,
        companyName,
        companyAddress,
        changePassword,
        isActive,
        userGroup.groupKey,
    ]);

    const submitHandler = useCallback((): void => {
        if (validateFields()) {
            toast.error(t('pleaseFillOutAllFields'), {
                hideProgressBar: true,
            });
            return;
        }

        if (userId && !hasChanged()) {
            navigate({ to: '/manage-users' });
            return;
        }

        setLoading(true);
        createOrUpdateUser({
            id: parseInt(userId),
            name,
            email,
            phoneNumber,
            companyName,
            companyAddress,
            password,
            isActive,
            userNotified: userId ? user!.userNotified : false,
            userGroup: userGroup?.groupKey,
            changePassword,
        })
            .then((message: string) => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
                navigate({ to: '/manage-users' });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
                if (userId) {
                    updateUserData();
                }
            })
            .finally(() => setLoading(false));
    }, [
        validateFields,
        userId,
        hasChanged,
        navigate,
        name,
        email,
        phoneNumber,
        companyName,
        companyAddress,
        password,
        isActive,
        user,
        userGroup?.groupKey,
        changePassword,
        t,
        updateUserData,
    ]);

    const keyUpHandler = useCallback((event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            submitHandler();
        }
    }, [submitHandler]);

    const userGroupOptionEqualToValueHandler = useCallback((option: UserGroup, value: UserGroup): boolean => {
        return option.groupKey === value.groupKey;
    }, []);

    const userGroupOptionLabelHandler = useCallback((option: UserGroup) => option.groupName, []);

    const userGroupRenderInputHandler = useCallback((params: AutocompleteRenderInputParams) => {
        return (<TextField
            {...params}
            label={t('userGroup')}
        />);
    }, [t]);

    return (
        <div className="admin-page-container user-container">
            <Backdrop open={isLoading || loading}
                      sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress sx={{ color: 'var(--background-color)' }} />
            </Backdrop>
            <div className="admin-page-wrapper user-wrapper">
                <Link to="/manage-users" className="back-link">
                    <BackIcon />
                </Link>
                <div className="admin-page-title user-title">{userId ? t('editUser') : t('newUser')}</div>
                <div className="admin-page-content user-content">
                    <div className="row">
                        <TextField
                            id="name-input"
                            label={t('name')}
                            value={name}
                            placeholder={t('userName')}
                            onChange={(e) => setName(e.target.value)}
                            error={invalidFields.name}
                            helperText={invalidFields.name ? t('nameInvalid') : ''}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            id="email-input"
                            label={t('email')}
                            value={email}
                            placeholder={t('userEmail')}
                            onChange={(e) => setEmail(e.target.value)}
                            error={invalidFields.email}
                            helperText={invalidFields.email ? (userSelectOptions?.emails.includes(email) ? t('emailAlreadyInUse') : t('emailInvalid')) : ''}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            id="phone-number-input"
                            label={t('phoneNumber')}
                            value={phoneNumber}
                            placeholder="+1234567890"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            id="company-name-input"
                            label={t('companyName')}
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            error={invalidFields.companyName}
                            helperText={invalidFields.companyName ? t('companyNameInvalid') : ''}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            id="company-address-input"
                            label={t('companyAddress')}
                            value={companyAddress}
                            onChange={(e) => setCompanyAddress(e.target.value)}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                        />
                    </div>
                    {!!userId &&
                        <div className="row">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={changePassword}
                                        onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                                setChangePassword((prevState: boolean) => !prevState);
                                            }
                                        }
                                        sx={{
                                            color: 'var(--background-color)',
                                            '&.Mui-checked': {
                                                color: 'var(--background-color)',
                                            },
                                        }}
                                    />
                                }
                                label={t('changePassword')}
                            />
                        </div>
                    }
                    <div className="row">
                        <TextField
                            disabled={!!userId && !changePassword}
                            id="password-input"
                            label={t('password')}
                            type="password"
                            autoComplete="off"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={invalidFields.password}
                            helperText={invalidFields.password ? t('registerPasswordInvalid') : ''}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                        />
                        <ul className="password-strength-container">
                            <li>{t('passwordStrength')}</li>
                            <li>{t('oneUpperCaseLetter')}</li>
                            <li>{t('oneLowerCaseLetter')}</li>
                            <li>{t('oneNumber')}</li>
                            <li>{t('minimumEightCharacters')}</li>
                            <li>{t('noSpaces')}</li>
                        </ul>
                    </div>
                    <div className="row">
                        <TextField
                            disabled={!!userId && !changePassword}
                            id="repeat-password-input"
                            label={t('repeatPassword')}
                            type="password"
                            autoComplete="off"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            error={invalidFields.repeatPassword}
                            helperText={invalidFields.repeatPassword ? t('repeatPasswordInvalid') : ''}
                            sx={{background: '#fff', position: 'relative'}}
                            onKeyUp={keyUpHandler}
                        />
                    </div>
                    <div className="row">
                        <FormControlLabel
                            control={
                                <Switch
                                    value={isActive}
                                    checked={isActive}
                                    onChange={(e) => setIsActive(e.target.checked)}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: 'var(--background-color)',
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                                            backgroundColor: 'var(--background-color)',
                                        }
                                    }}/>
                            }
                            label={t('isActive')}
                            labelPlacement="top"
                        />
                    </div>
                    <div className="row">
                        <Autocomplete
                            disablePortal
                            id="user-group-select"
                            options={userSelectOptions?.userGroups ?? []}
                            isOptionEqualToValue={userGroupOptionEqualToValueHandler}
                            getOptionLabel={userGroupOptionLabelHandler}
                            value={userGroup}
                            loading={isLoading}
                            disableClearable={true}
                            defaultValue={userGroup}
                            onChange={(event: any, newValue: UserGroup): void => setUserGroup(newValue)}
                            renderInput={userGroupRenderInputHandler}
                            sx={{background: '#fff', position: 'relative'}}
                        />
                    </div>
                    <Button variant="contained"
                            className="action-button"
                            onClick={submitHandler}
                            onKeyUp={keyUpHandler}
                    >
                        {t('save')}
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default AdminUser;
