import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getToken, requestHandler } from '../helpers/Utils';
import {
    AuthResponse,
    User,
    LoginResponse,
    CoursesAggregate,
    CourseValidationData,
    Course,
    CourseUserData,
} from '../../../server/types/core';

function useGetCourses(userId?: number, userGroup?: string): UseQueryResult<CoursesAggregate, AxiosError> {
    return useQuery({
        queryKey: ['courses', userId, userGroup],
        queryFn: () => getCourses(userId, userGroup),
    });
}

function useGetCourse(
    {
        courseId,
        userId,
    }: {
        courseId: number,
        userId?: number,
    }
): UseQueryResult<Course, AxiosError> {
    return useQuery({
        queryKey: ['course', courseId, userId],
        queryFn: () => getCourse({ courseId, userId }),
    });
}

function getLoginState(): Promise<AuthResponse> {
    const token: string | null = getToken();

    if (!token) {
        return new Promise((resolve) => resolve({ loggedIn: false }));
    }

    return axios.post<AuthResponse>('/api/getUser', { token })
        .then((response) => response.data);
}

function loginUser(email: string, password: string): Promise<LoginResponse> {
    return axios.post<LoginResponse>('/api/login', { email, password })
        .then((response) => response.data);
}

function logoutUser(id: number): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/api/logout',
        data: { id },
    });
}

function registerUser(user: User): Promise<string> {
    return axios.post<string>('/api/register', user)
        .then((response) => response.data);
}

function getCourses(userId?: number, userGroup?: string): Promise<CoursesAggregate> {
    const token: string | null = getToken();

    return axios.post<CoursesAggregate>('/api/getCourses', {
        userId,
        userGroup,
        token,
    }).then((response) => response.data);
}

function getCourseValidationData(id: number): Promise<CourseValidationData> {
    return axios.post<CourseValidationData>('/api/getCourseValidationData', { id })
        .then((response) => response.data);
}

function getCourse(
    {
        courseId,
        userId,
    }: {
        courseId: number,
        userId?: number,
    }
): Promise<Course> {
    const token: string | null = getToken();

    return axios.post<Course>('/api/getCourse', {
        courseId,
        userId,
        token,
    }).then((response) => response.data);
}

function setCourseUserData(data: CourseUserData): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/api/setCourseUserData',
        data: data,
    });
}

function getCourseDiploma(courseId: number, userId: number): Promise<ArrayBuffer> {
    return requestHandler<ArrayBuffer>({
        type: 'post',
        endpoint: '/api/getCourseDiploma',
        data: { courseId, userId },
        config: { responseType: 'arraybuffer' },
    });
}

function updateUser(data: Partial<User>): Promise<string> {
    return requestHandler<string>({
        type: 'post',
        endpoint: '/api/updateUser',
        data: data,
    });
}

export {
    useGetCourses,
    useGetCourse,
    getLoginState,
    loginUser,
    logoutUser,
    registerUser,
    getCourseValidationData,
    setCourseUserData,
    getCourseDiploma,
    updateUser,
}
