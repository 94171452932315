import React, { memo, useState, useCallback } from 'react';
import { useLoaderData } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import {
    Backdrop,
    CircularProgress,
    Theme,
    TextField,
    Button,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { updateUser } from '../api/Utils';
import { toast } from 'react-toastify';
import { validPhoneNumber, validPassword } from '../helpers/Regex';
import { AxiosError } from 'axios';
import type { User } from '../../../server/types/core';
import '../styles/Profile.css';

const Profile = memo(function Profile(): React.ReactElement {
    const { t } = useTranslation();
    const { user }: { user: User } = useLoaderData({ strict: false });
    const [name, setName] = useState<string | undefined>(user.name);
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(user.phoneNumber);
    const [companyName, setCompanyName] = useState<string | undefined>(user.companyName);
    const [companyAddress, setCompanyAddress] = useState<string | undefined>(user.companyAddress);
    const [changePassword, setChangePassword] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [invalidFields, setInvalidFields] = useState<{ [key: string]: boolean }>({
        name: false,
        phoneNumber: false,
        companyName: false,
        companyAddress: false,
        password: false,
        repeatPassword: false,
    });
    const [loading, setLoading] = useState<boolean>(false);

    const validateFields = useCallback((): boolean => {
        const nameInvalid: boolean = name === '';
        const phoneNumberInvalid: boolean = phoneNumber === '' || !validPhoneNumber.test(phoneNumber!);
        const companyNameInvalid: boolean = companyName === '';
        const companyAddressInvalid: boolean = companyAddress === '';
        const passwordInvalid: boolean = changePassword && (password === '' || !validPassword.test(password));
        const repeatPasswordInvalid: boolean = changePassword && (repeatPassword !== password);

        setInvalidFields({
            name: nameInvalid,
            phoneNumber: phoneNumberInvalid,
            companyName: companyNameInvalid,
            companyAddress: companyAddressInvalid,
            password: passwordInvalid,
            repeatPassword: repeatPasswordInvalid,
        });

        return nameInvalid
            || phoneNumberInvalid
            || companyNameInvalid
            || companyAddressInvalid
            || passwordInvalid
            || repeatPasswordInvalid;
    }, [
        name,
        phoneNumber,
        companyName,
        companyAddress,
        changePassword,
        password,
        repeatPassword,
    ]);

    const hasChanged = useCallback((): boolean => {
        return name !== user.name
            || phoneNumber !== user.phoneNumber
            || companyName !== user.companyName
            || companyAddress !== user.companyAddress
            || changePassword;
    }, [
        name,
        user,
        phoneNumber,
        companyName,
        companyAddress,
        changePassword,
    ]);

    const submitHandler = useCallback((): void => {
        if (validateFields()) {
            toast.error(t('pleaseFillOutAllFields'), {
                hideProgressBar: true,
            });
            return;
        }

        if (!hasChanged()) {
            return;
        }

        setLoading(true);
        updateUser({
            id: user.id,
            name,
            phoneNumber,
            companyName,
            companyAddress,
            password,
            changePassword,
        })
            .then((message: string) => {
                toast.success(t(message), {
                    hideProgressBar: true,
                });
            })
            .catch((error: AxiosError): void => {
                const { responseText } = error.request;
                const errorMessage: string = responseText ? responseText : error.message;
                toast.error(t(errorMessage), {
                    hideProgressBar: true,
                });
            })
            .finally(() => setLoading(false));
    }, [
        validateFields,
        hasChanged,
        user,
        name,
        phoneNumber,
        companyName,
        companyAddress,
        password,
        changePassword,
        t,
    ]);

    const keyUpHandler = useCallback((event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            submitHandler();
        }
    }, [submitHandler]);

    return (
        <div className="profile-container">
            <Backdrop open={loading}
                      sx={{zIndex: (theme: Theme) => theme.zIndex.drawer + 1}}
            >
                <CircularProgress sx={{color: 'var(--background-color)'}}/>
            </Backdrop>
            <div className="profile-form-wrapper">
                <div className="profile-title">{t('profile')}</div>
                <div className="row">
                    <TextField
                        id="name-input"
                        label={t('name')}
                        value={name}
                        placeholder={t('userName')}
                        onChange={(e) => setName(e.target.value)}
                        error={invalidFields.name}
                        helperText={invalidFields.name ? t('nameInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                </div>
                <div className="row">
                    <TextField
                        disabled
                        id="email-input"
                        label={t('email')}
                        value={user.email}
                        sx={{background: '#fff', position: 'relative'}}
                    />
                </div>
                <div className="row">
                    <TextField
                        id="phone-number-input"
                        label={t('phoneNumber')}
                        value={phoneNumber}
                        placeholder="+1234567890"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        error={invalidFields.phoneNumber}
                        helperText={invalidFields.phoneNumber ? t('phoneNumberInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                </div>
                <div className="row">
                    <TextField
                        id="company-name-input"
                        label={t('companyName')}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        error={invalidFields.companyName}
                        helperText={invalidFields.companyName ? t('companyNameInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                </div>
                <div className="row">
                    <TextField
                        id="company-address-input"
                        label={t('companyAddress')}
                        value={companyAddress}
                        onChange={(e) => setCompanyAddress(e.target.value)}
                        error={invalidFields.companyAddress}
                        helperText={invalidFields.companyAddress ? t('companyAddressInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                </div>
                <div className="row">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={changePassword}
                                onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) => {
                                        setChangePassword((prevState: boolean) => !prevState);
                                    }
                                }
                                sx={{
                                    color: 'var(--background-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--background-color)',
                                    },
                                }}
                            />
                        }
                        label={t('changePassword')}
                    />
                </div>
                <div className="row">
                    <TextField
                        disabled={!changePassword}
                        id="password-input"
                        label={t('password')}
                        type="password"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={invalidFields.password}
                        helperText={invalidFields.password ? t('registerPasswordInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                    <ul className="password-strength-container">
                        <li>{t('passwordStrength')}</li>
                        <li>{t('oneUpperCaseLetter')}</li>
                        <li>{t('oneLowerCaseLetter')}</li>
                        <li>{t('oneNumber')}</li>
                        <li>{t('minimumEightCharacters')}</li>
                        <li>{t('noSpaces')}</li>
                    </ul>
                </div>
                <div className="row">
                    <TextField
                        disabled={!changePassword}
                        id="repeat-password-input"
                        label={t('repeatPassword')}
                        type="password"
                        autoComplete="off"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        error={invalidFields.repeatPassword}
                        helperText={invalidFields.repeatPassword ? t('repeatPasswordInvalid') : ''}
                        sx={{background: '#fff', position: 'relative'}}
                        onKeyUp={keyUpHandler}
                    />
                </div>
                <Button variant="contained"
                        onClick={() => submitHandler()}
                        onKeyUp={keyUpHandler}
                >{t('save')}</Button>
            </div>
        </div>
    );
});

export default Profile;
