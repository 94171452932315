import React from 'react';
import {
    createRootRoute,
    Outlet,
    createRoute,
    createRouter,
    redirect,
} from '@tanstack/react-router';
import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
import Footer from './Footer';
import Homepage from './Homepage';
import Login from './Login';
import Register from './Register';
import Courses from './Courses';
import Course from './Course';
import Profile from './Profile';
import About from './About';
import AdminLogin from './Admin/Login';
import Menu from './Admin/Menu';
import Dashboard from './Admin/Dashboard';
import ManageCourses from './Admin/ManageCourses';
import AdminCourse from './Admin/Course';
import DeletedCourses from './Admin/DeletedCourses';
import ManageUsers from './Admin/ManageUsers';
import User from './Admin/User';
import CompletedCourses from './Admin/CompletedCourses';
import DeletedUsers from './Admin/DeletedUsers';
import ManageSignups from './Admin/ManageSignups';
import SignupList from './Admin/SignupList';
import ManageUserGroups from './Admin/ManageUserGroups';
import UserGroup from './Admin/UserGroup';
import Configurations from './Admin/Configurations';
import LanguageSwitcher from './LanguageSwitcher';
import NotFound from './NotFound';
import { getLoginState, getCourseValidationData } from '../api/Utils';
import { getLoginState as getAdminLoginState } from '../api/Admin/Utils';
import type { AuthResponse, CourseValidationData, User as UserProps } from '../../../server/types/core';
import type { AuthResponse as AdminAuthResponse } from '../../../server/types/admin/core';
import 'react-toastify/dist/ReactToastify.css';

const rootRoute = createRootRoute({
    component: () => (
        <>
            <ToastContainer />
            <Outlet />
        </>
    ),
});

const frontendLayoutRoute = createRoute({
    getParentRoute: () => rootRoute,
    id: 'frontend-layout',
    component: () => (
        <div className="frontend-page frontend-container">
            <Header />
            <Outlet />
            <Footer />
        </div>
    ),
});

const adminLoginLayoutRoute = createRoute({
    getParentRoute: () => rootRoute,
    id: 'admin-login-layout',
    component: () => (
        <>
            <div className="admin-page admin-login-container">
                <LanguageSwitcher />
                <Outlet />
            </div>
        </>
    ),
    beforeLoad: async (): Promise<void> => {
        try {
            const { loggedIn }: AdminAuthResponse = await getAdminLoginState();

            if (loggedIn) {
                throw redirect({
                    to: '/dashboard',
                });
            }
        } catch (e: any) {
            if (e.isRedirect) {
                throw redirect({
                    to: '/dashboard',
                });
            }

            const { responseText } = e.request;
            const errorMessage: string = responseText ? responseText : e.message;
            toast.error(errorMessage, {
                hideProgressBar: true,
            });
        }
    },
});

const adminLayoutRoute = createRoute({
    getParentRoute: () => rootRoute,
    id: 'admin-layout',
    component: () => (
        <>
            <div className="admin-page admin-container">
                <Menu />
                <LanguageSwitcher />
                <Outlet />
            </div>
        </>
    ),
    beforeLoad: async ({ location }): Promise<void> => {
        try {
            const { loggedIn }: AdminAuthResponse = await getAdminLoginState();

            if (!loggedIn) {
                throw redirect({
                    to: '/admin',
                    search: {
                        redirect: location.href,
                    },
                });
            }
        } catch (e: any) {
            if (e.isRedirect) {
                throw redirect({
                    to: '/admin',
                    search: {
                        redirect: location.href,
                    },
                });
            }

            const { responseText } = e.request;
            const errorMessage: string = responseText ? responseText : e.message;
            toast.error(errorMessage, {
                hideProgressBar: true,
            });

            throw redirect({
                to: '/',
            });
        }
    },
});

const indexRoute = createRoute({
    getParentRoute: () => frontendLayoutRoute,
    path: '/',
    component: Homepage,
});

const loginRoute = createRoute({
    getParentRoute: () => frontendLayoutRoute,
    path: '/login',
    component: Login,
    beforeLoad: async (): Promise<void> => {
        try {
            const { loggedIn }: AuthResponse = await getLoginState();

            if (loggedIn) {
                throw redirect({
                    to: '/',
                });
            }
        } catch (e: any) {
            if (e.isRedirect) {
                throw redirect({
                    to: '/',
                });
            }

            const { responseText } = e.request;
            const errorMessage: string = responseText ? responseText : e.message;
            toast.error(errorMessage, {
                hideProgressBar: true,
            });
        }
    },
});

const registerRoute = createRoute({
    getParentRoute: () => frontendLayoutRoute,
    path: '/register',
    component: Register,
    beforeLoad: async (): Promise<void> => {
        try {
            const { loggedIn }: AuthResponse = await getLoginState();

            if (loggedIn) {
                throw redirect({
                    to: '/',
                });
            }
        } catch (e: any) {
            if (e.isRedirect) {
                throw redirect({
                    to: '/',
                });
            }

            const { responseText } = e.request;
            const errorMessage: string = responseText ? responseText : e.message;
            toast.error(errorMessage, {
                hideProgressBar: true,
            });
        }
    },
});

const coursesRoute = createRoute({
    getParentRoute: () => frontendLayoutRoute,
    path: '/courses',
    component: Outlet,
});

const coursesIndexRoute = createRoute({
    getParentRoute: () => coursesRoute,
    path: '/',
    component: Courses,
});

const courseRoute = createRoute({
    getParentRoute: () => coursesRoute,
    path: '/$courseId',
    component: Course,
    beforeLoad: async ({ params: { courseId } }): Promise<void> => {
        try {
            const {
                isPublic,
                assignedTo,
                assignedToGroups,
                parentId,
                isDeleted,
            }: CourseValidationData = await getCourseValidationData(parseInt(courseId));
            const { user }: AuthResponse = await getLoginState();

            if (parentId) {
                throw redirect({
                    to: '/courses/$courseId',
                    params: {
                        courseId: parentId.toString(),
                    },
                });
            }

            if (isDeleted || (!isPublic && (!user || (!assignedTo.includes(user.id!) && !assignedToGroups.includes(user.userGroup as string))))) {
                throw redirect({
                    to: '/courses',
                });
            }
        } catch (e: any) {
            if (e.isRedirect) {
                throw redirect({
                    to: e.to,
                    params: e.params,
                });
            }

            const { responseText } = e.request;
            const errorMessage: string = responseText ? responseText : e.message;
            toast.error(errorMessage, {
                hideProgressBar: true,
            });

            throw redirect({
                to: '/courses',
            });
        }
    },
});

const profileRoute = createRoute({
    getParentRoute: () => frontendLayoutRoute,
    path: '/profile',
    component: Profile,
    loader: ({ context }) => context,
    gcTime: 0,
    beforeLoad: async ({ location }): Promise<{ user: UserProps | undefined }> => {
        try {
            const { loggedIn, user }: AuthResponse = await getLoginState();

            if (!loggedIn) {
                throw redirect({
                    to: '/login',
                    search: {
                        redirect: location.href,
                    },
                });
            }

            return { user };
        } catch (e: any) {
            if (e.isRedirect) {
                throw redirect({
                    to: '/login',
                    search: {
                        redirect: location.href,
                    },
                });
            }

            const { responseText } = e.request;
            const errorMessage: string = responseText ? responseText : e.message;
            toast.error(errorMessage, {
                hideProgressBar: true,
            });

            throw redirect({
                to: '/login',
            });
        }
    },
});

const aboutRoute = createRoute({
    getParentRoute: () => frontendLayoutRoute,
    path: '/about',
    component: About,
});

const adminLoginRoute = createRoute({
    getParentRoute: () => adminLoginLayoutRoute,
    path: '/admin',
    component: AdminLogin,
});

const dashboardRoute = createRoute({
    getParentRoute: () => adminLayoutRoute,
    path: '/dashboard',
    component: Dashboard,
});

const manageCoursesRoute = createRoute({
    getParentRoute: () => adminLayoutRoute,
    path: '/manage-courses',
    component: Outlet,
});

const manageCoursesIndexRoute = createRoute({
    getParentRoute: () => manageCoursesRoute,
    path: '/',
    component: ManageCourses,
});

const newCourseRoute = createRoute({
    getParentRoute: () => manageCoursesRoute,
    path: '/new',
    component: AdminCourse,
});

const editCourseRoute = createRoute({
    getParentRoute: () => manageCoursesRoute,
    path: '/edit/$courseId',
    component: AdminCourse,
});

const deletedCoursesRoute = createRoute({
    getParentRoute: () => manageCoursesRoute,
    path: '/deleted',
    component: DeletedCourses,
});

const manageUsersRoute = createRoute({
    getParentRoute: () => adminLayoutRoute,
    path: '/manage-users',
    component: Outlet,
});

const manageUsersIndexRoute = createRoute({
    getParentRoute: () => manageUsersRoute,
    path: '/',
    component: ManageUsers,
});

const newUserRoute = createRoute({
    getParentRoute: () => manageUsersRoute,
    path: '/new',
    component: User,
});

const editUserRoute = createRoute({
    getParentRoute: () => manageUsersRoute,
    path: '/edit/$userId',
    component: User,
});

const viewUserRoute = createRoute({
    getParentRoute: () => manageUsersRoute,
    path: '/view/$userId',
    component: CompletedCourses,
});

const deletedUsersRoute = createRoute({
    getParentRoute: () => manageUsersRoute,
    path: '/deleted',
    component: DeletedUsers,
});

const manageSignupsRoute = createRoute({
    getParentRoute: () => adminLayoutRoute,
    path: '/manage-signups',
    component: Outlet,
});

const manageSignupsIndexRoute = createRoute({
    getParentRoute: () => manageSignupsRoute,
    path: '/',
    component: ManageSignups,
});

const viewSignupRoute = createRoute({
    getParentRoute: () => manageSignupsRoute,
    path: '/view/$courseId',
    component: SignupList,
});

const manageUserGroupsRoute = createRoute({
    getParentRoute: () => adminLayoutRoute,
    path: '/manage-user-groups',
    component: Outlet,
});

const manageUserGroupsIndexRoute = createRoute({
    getParentRoute: () => manageUserGroupsRoute,
    path: '/',
    component: ManageUserGroups,
});

const newUserGroupRoute = createRoute({
    getParentRoute: () => manageUserGroupsRoute,
    path: '/new',
    component: UserGroup,
});

const editUserGroupRoute = createRoute({
    getParentRoute: () => manageUserGroupsRoute,
    path: '/edit/$userGroupKey',
    component: UserGroup,
});

const configurationsRoute = createRoute({
    getParentRoute: () => adminLayoutRoute,
    path: '/configurations',
    component: Configurations,
});

const routeTree = rootRoute.addChildren([
    frontendLayoutRoute.addChildren([
        indexRoute,
        loginRoute,
        registerRoute,
        coursesRoute.addChildren([
            coursesIndexRoute,
            courseRoute,
        ]),
        profileRoute,
        aboutRoute,
    ]),
    adminLoginLayoutRoute.addChildren([
        adminLoginRoute,
    ]),
    adminLayoutRoute.addChildren([
        dashboardRoute,
        manageCoursesRoute.addChildren([
            manageCoursesIndexRoute,
            newCourseRoute,
            editCourseRoute,
            deletedCoursesRoute,
        ]),
        manageUsersRoute.addChildren([
            manageUsersIndexRoute,
            newUserRoute,
            editUserRoute,
            viewUserRoute,
            deletedUsersRoute,
        ]),
        manageSignupsRoute.addChildren([
            manageSignupsIndexRoute,
            viewSignupRoute,
        ]),
        manageUserGroupsRoute.addChildren([
            manageUserGroupsIndexRoute,
            newUserGroupRoute,
            editUserGroupRoute,
        ]),
        configurationsRoute,
    ]),
]);

const router = createRouter({
    routeTree,
    defaultNotFoundComponent: NotFound,
});

export default router;
